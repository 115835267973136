<template>
  <component
    class="trusted-shop-badge"
    :is="rootTag"
  >
    <div id="trustbadgeCustomContainer" />
    <template
      v-if="loadTrustedShop"
    >
      <script-loader
        v-bind="tsData"
        :src="jsUrl"
      />
      <div
        class="trusted-shop-badge__more-link"
        v-if="linkUrl"
      >
        <a
          :href="linkUrl"
          target="_blank"
          v-html="linkText"
        />
      </div>
    </template>
  </component>
</template>

<script setup lang="ts">
  import {ref, computed, onMounted} from 'vue';
  import ScriptLoader from '../scriptLoader/scriptLoader.vue';
  import LazyLoad from '../lazyLoad/lazyLoad.vue';

  declare global {
    interface Window {
      _tsConfig: any;
    }
  }

  const props = withDefaults(defineProps<{
    trustedData?: Record<string, any>;
    jsUrl: string;
    linkText?: string;
    linkUrl?: string;
    height?: string;
    variant?: string;
    isLazyLoad?: boolean;
  }>(), {
    trustedData: null,
    linkText: '',
    linkUrl: '',
    height: '70',
    variant: 'custom_reviews',
    isLazyLoad: true
  });

  const loadTrustedShop = ref(false);
  const tsData = {
    'customBadgeHeight': props.height,
    'variant': props.variant,
    'customElementId': 'trustbadgeCustomContainer',
    'disableResponsive': 'true', /* deactivate responsive behaviour */
    'disableTrustbadge': 'false', /* deactivate trustbadge */
    'trustCardTrigger': 'click', /* set to 'click' if you want the trustcard to be opened on click instead */
  }

  onMounted(() => {
    if (!window._tsConfig) {
      window._tsConfig = tsData;
      loadTrustedShop.value = true;
    }
  })

  const rootTag = computed(() => {
    const isCheckoutConfirmationPage = document.querySelector('.checkout-confirmation-layout');
    const lazy = !isCheckoutConfirmationPage || props.isLazyLoad;
    return lazy ? LazyLoad : 'div';
  });
</script>
