import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "product-box-wide__segment-center" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "product-box-wide__header-text product-box__header-text" }
const _hoisted_4 = {
  key: 0,
  class: "product-box-wide__vintage product-box__vintage"
}
const _hoisted_5 = { class: "product-box-wide__name product-box__name" }
const _hoisted_6 = {
  key: 1,
  class: "product-box__short-description"
}
const _hoisted_7 = {
  key: 2,
  class: "product-box-wide__short-description product-box__short-description"
}
const _hoisted_8 = {
  key: 0,
  class: "product-box-wide__rating-container"
}
const _hoisted_9 = ["href"]
const _hoisted_10 = ["href"]
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      class: "product-box-wide__header product-box__segment-header",
      href: _ctx.productBoxData.url
    }, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.productBoxData.vintage)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.productBoxData.vintage), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _cache[0] || (_cache[0] = _createTextVNode()),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.productBoxData.description1), 1 /* TEXT */),
        _cache[1] || (_cache[1] = _createTextVNode()),
        (_ctx.productBoxData.description2)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.productBoxData.description2), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _cache[2] || (_cache[2] = _createTextVNode()),
        (_ctx.productBoxData.shortDescription)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.productBoxData.shortDescription), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ])
    ], 8 /* PROPS */, _hoisted_2),
    _cache[6] || (_cache[6] = _createTextVNode()),
    (_ctx.productBoxData.ratings > _ctx.minimumRating || _ctx.productBoxData.inBasket)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "product-box-wide__bottle-amount" }, null, -1 /* HOISTED */)),
          _cache[4] || (_cache[4] = _createTextVNode()),
          (_ctx.productBoxData.inBasket)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "product-box__status product-box__status--basket product-box-wide__status",
                href: _ctx.basketUrl
              }, _toDisplayString(_ctx.$t('ads.ads_top_info.article_in_basket')), 9 /* TEXT, PROPS */, _hoisted_9))
            : _createCommentVNode("v-if", true),
          _cache[5] || (_cache[5] = _createTextVNode()),
          (_ctx.state === 'successorInBasket')
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                class: "product-box__status product-box__status--basket",
                href: _ctx.basketUrl
              }, _toDisplayString(_ctx.$t('ads.ads_top_info.successor_in_basket')), 9 /* TEXT, PROPS */, _hoisted_10))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    _cache[7] || (_cache[7] = _createTextVNode()),
    _createElementVNode("div", {
      class: "product-box-wide__long-description",
      innerHTML: _ctx.descriptionExcerpt
    }, null, 8 /* PROPS */, _hoisted_11)
  ]))
}