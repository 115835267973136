import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "ratings" }
const _hoisted_3 = { class: "ratings__wrapper" }
const _hoisted_4 = ["for", "onMouseover"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.productBoxData.valid)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: _ctx.productStarHref,
        "aria-label": "product review",
        class: "product-box__segment-review"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("template", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ratings, (rating, index) => {
                return (_openBlock(), _createElementBlock("span", {
                  class: _normalizeClass(["ratings__label ratings__star radio-input", {
              [`ratings__label--index-${5 - index}`]: true,
              'ratings__star--empty': true
            }]),
                  key: `${5 - index}_${rating.value}`,
                  for: rating.value,
                  onMouseover: ($event: any) => (_ctx.changeRating(rating.value)),
                  onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeRating(_ctx.initialRating)))
                }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_4))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ])
        ]),
        _cache[1] || (_cache[1] = _createTextVNode()),
        _createElementVNode("div", {
          class: "product-box__segment-review__headline",
          innerHTML: _ctx.$t('productbox.product.review.headline')
        }, null, 8 /* PROPS */, _hoisted_5)
      ], 8 /* PROPS */, _hoisted_1))
    : _createCommentVNode("v-if", true)
}