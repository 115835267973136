<template>
  <div
    v-if="value"
    class="price-info"
    :class="{
      'price-info--reduction': value.old,
      [`price-info--currency-${value.currency}`]: true
    }"
    :aria-label="$t('checkout.basket.position.header.price') + ' ' + value.current"
  >
    <div
      class="price-info__old"
      v-if="value.old"
    >
      <span class="price-info__old-text">{{ $t('price.instead_of') }}</span>
      <span class="price-info__old-value">{{ value.old }}</span>
    </div>

    <div class="price-info__current">
      <span class="price-info__value">
        {{ value.currentValue }}
      </span>
      <span class="price-info__currency">
        {{ value.currency }}
      </span>
      <sup
        v-if="showStar"
        class="price-info__asterisk"
      >
        *
      </sup>
    </div>

    <div class="price-info__unit">
      <span class="price-info__unit-text">
        {{ getUnitText() }}
      </span>
      <span
        v-if="value.unitPrice"
        class="price-info__unit-price"
      >
        {{ value.unitPrice }}<template v-if="value.unit">/{{ value.unit }}</template>
      </span>
    </div>

    <div
      v-if="showTax"
      class="price-info__tax"
      v-html="$t(showShippingIncluded ? messageKey.deliveryInclShip : messageKey.deliveryInclVat, [shippingInfoUrl])"
    />
  </div>
</template>

<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import type Price from '../../types/Price';

  @Options({
    name: 'price-info'
  })
  export default class PriceInfo extends Vue {
    @Prop({required: true})
    value: Price;

    @Prop({type: Boolean, default: false})
    showStar: boolean;

    @Prop({type: Boolean, default: false})
    showTax: boolean;

    @Prop({type: Boolean, default: false})
    showShippingIncluded: boolean;

    @Prop({type: Boolean, default: false})
    showSavedPercentage: boolean    // vinos only, not implemented in bps template

    @Prop()
    shippingInfoUrl: string;

    @Prop()
    foodSpecificationLink: string;

    messageKey: {[key: string]: any} = {
      deliveryInclShip: 'price.incl_vat_incl_ship',
      deliveryInclVat: 'price.incl_vat',
    };

    mounted() {
      if (window.__productConfig && window.__productConfig.messageKey) {
        this.messageKey = window.__productConfig.messageKey;
      }
    }

    getUnitText() {
      let result = this.$t(`product.price.volumeUnit.${this.value.volumeUnit}`);
      if (this.value.unitPrice) {
        if (this.value.packageSize) {
          result += ' (' + this.value.packageSize + ')';
        }
        result += ',';
      }

      return result;
    }
  }
</script>
