<template>
  <component
    :is="'script'"
    :src="src"
    v-bind="$attrs"
  />
</template>

<script lang="ts">
  import {defineComponent} from 'vue';

  export default defineComponent({
    name: 'ScriptLoader',
    inheritAttrs: false,

    props: {
      src: {
        type: String,
        required: true,
      },
    },
  });
</script>
