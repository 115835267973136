import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "trusted-shop-badge__more-link"
}
const _hoisted_2 = ["href", "innerHTML"]

import {ref, computed, onMounted} from 'vue';
  import ScriptLoader from '../scriptLoader/scriptLoader.vue';
  import LazyLoad from '../lazyLoad/lazyLoad.vue';

  declare global {
    interface Window {
      _tsConfig: any;
    }
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'trustedShopBadge',
  props: {
    trustedData: { default: null },
    jsUrl: {},
    linkText: { default: '' },
    linkUrl: { default: '' },
    height: { default: '70' },
    variant: { default: 'custom_reviews' },
    isLazyLoad: { type: Boolean, default: true }
  },
  setup(__props: any) {

  const props = __props;

  const loadTrustedShop = ref(false);
  const tsData = {
    'customBadgeHeight': props.height,
    'variant': props.variant,
    'customElementId': 'trustbadgeCustomContainer',
    'disableResponsive': 'true', /* deactivate responsive behaviour */
    'disableTrustbadge': 'false', /* deactivate trustbadge */
    'trustCardTrigger': 'click', /* set to 'click' if you want the trustcard to be opened on click instead */
  }

  onMounted(() => {
    if (!window._tsConfig) {
      window._tsConfig = tsData;
      loadTrustedShop.value = true;
    }
  })

  const rootTag = computed(() => {
    const isCheckoutConfirmationPage = document.querySelector('.checkout-confirmation-layout');
    const lazy = !isCheckoutConfirmationPage || props.isLazyLoad;
    return lazy ? LazyLoad : 'div';
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(rootTag.value), { class: "trusted-shop-badge" }, {
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("div", { id: "trustbadgeCustomContainer" }, null, -1 /* HOISTED */)),
      _cache[2] || (_cache[2] = _createTextVNode()),
      (loadTrustedShop.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(ScriptLoader, _mergeProps(tsData, { src: _ctx.jsUrl }), null, 16 /* FULL_PROPS */, ["src"]),
            _cache[0] || (_cache[0] = _createTextVNode()),
            (_ctx.linkUrl)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("a", {
                    href: _ctx.linkUrl,
                    target: "_blank",
                    innerHTML: _ctx.linkText
                  }, null, 8 /* PROPS */, _hoisted_2)
                ]))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}
}

})