import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "product-box__segment-main" }
const _hoisted_2 = {
  key: 1,
  class: "product-box__area"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  key: 5,
  class: "product-box__price-wrapper product-box__price-wrapper--no-price"
}
const _hoisted_6 = { class: "product-box__buttons" }
const _hoisted_7 = ["href"]
const _hoisted_8 = {
  key: 6,
  class: "product-box__price-wrapper product-box__price-wrapper--no-price"
}
const _hoisted_9 = { class: "product-box__buttons" }
const _hoisted_10 = ["href"]
const _hoisted_11 = {
  key: 7,
  class: "product-box__conversion"
}
const _hoisted_12 = {
  key: 2,
  class: "product-box__price-wrapper"
}
const _hoisted_13 = ["href", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ratings = _resolveComponent("ratings")!
  const _component_flags = _resolveComponent("flags")!
  const _component_tags = _resolveComponent("tags")!
  const _component_lazy_load = _resolveComponent("lazy-load")!
  const _component_availability_info = _resolveComponent("availability-info")!
  const _component_winemaker_price = _resolveComponent("winemaker-price")!
  const _component_price_info = _resolveComponent("price-info")!
  const _component_product_qty_and_cart_btn = _resolveComponent("product-qty-and-cart-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.productBoxData.ratings > _ctx.minimumRating && _ctx.isBoxTypeVertical)
      ? (_openBlock(), _createBlock(_component_ratings, {
          key: 0,
          class: "product-box__ratings",
          rating: _ctx.productBoxData.ratings,
          "ratings-counter": _ctx.productBoxData.ratingsCounter,
          type: _ctx.settings.rating.type,
          "lazy-load": true
        }, null, 8 /* PROPS */, ["rating", "ratings-counter", "type"]))
      : _createCommentVNode("v-if", true),
    _cache[8] || (_cache[8] = _createTextVNode()),
    (_ctx.isBoxTypeVertical && _ctx.productBoxData.area && _ctx.settings.showArea)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.productBoxData.area), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _cache[9] || (_cache[9] = _createTextVNode()),
    ((_ctx.productBoxData.flags || _ctx.productBoxData.tags) && !_ctx.productBoxData.inBasket)
      ? (_openBlock(), _createBlock(_component_lazy_load, {
          key: 2,
          class: "product-box__tags-wrapper",
          role: "presentation"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_flags, {
              class: _normalizeClass({'product-box__product-flags--hidden': _ctx.productBoxData.inBasket}),
              flags: _ctx.productBoxData.flags,
              limit: _ctx.isBoxTypeVertical ? 4 : 3,
              "award-limit": _ctx.isBoxTypeVertical ? 2 : 1,
              "white-list": _ctx.settings.flags.whitelistTag
            }, null, 8 /* PROPS */, ["class", "flags", "limit", "award-limit", "white-list"]),
            _cache[4] || (_cache[4] = _createTextVNode()),
            (_ctx.isBoxTypeVertical)
              ? (_openBlock(), _createBlock(_component_tags, {
                  key: 0,
                  class: _normalizeClass(["product-box__tags", {
          'product-box__tags--more': _ctx.productBoxData.tags.length > 2,
          'product-box__tags--has-award': _ctx.productBoxData.flags.awardImageUrls && _ctx.productBoxData.flags.awardImageUrls.length
        }]),
                  tags: _ctx.productBoxData.tags,
                  onQuantityChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('quantityChange', $event)))
                }, null, 8 /* PROPS */, ["class", "tags"]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _cache[10] || (_cache[10] = _createTextVNode()),
    (_ctx.isBoxTypeVertical && _ctx.productBoxData.inBasket)
      ? (_openBlock(), _createElementBlock("a", {
          key: 3,
          class: "product-box__status product-box__status--basket",
          href: _ctx.basketUrl
        }, _toDisplayString(_ctx.$t('ads.ads_top_info.article_in_basket')), 9 /* TEXT, PROPS */, _hoisted_3))
      : _createCommentVNode("v-if", true),
    _cache[11] || (_cache[11] = _createTextVNode()),
    (_ctx.isBoxTypeVertical && _ctx.productBoxData.successorInBasket && !_ctx.productBoxData.inBasket && _ctx.addSuccessorActive)
      ? (_openBlock(), _createElementBlock("a", {
          key: 4,
          class: "product-box__status product-box__status--basket product-box__status--successor-basket",
          href: _ctx.basketUrl
        }, _toDisplayString(_ctx.$t('ads.ads_top_info.successor_in_basket')), 9 /* TEXT, PROPS */, _hoisted_4))
      : _createCommentVNode("v-if", true),
    _cache[12] || (_cache[12] = _createTextVNode()),
    (_ctx.productBoxData.zeroPriceItem || _ctx.productBoxData.recurringOrderFollowUpPacket)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("a", {
              class: "product-box__status product-box__status--unavailable",
              href: _ctx.productBoxData.url
            }, _toDisplayString(_ctx.$t('availability.no-price')), 9 /* TEXT, PROPS */, _hoisted_7)
          ])
        ]))
      : (_ctx.productBoxData.availability.subscriptionPreview)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("a", {
                class: "product-box__status product-box__status--unavailable",
                href: _ctx.productBoxData.url
              }, _toDisplayString(_ctx.$t('availability.date.not.valid.yet')), 9 /* TEXT, PROPS */, _hoisted_10)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_11, [
            (_ctx.showAvailability)
              ? (_openBlock(), _createBlock(_component_availability_info, {
                  key: 0,
                  class: "product-box__availability",
                  availability: _ctx.productBoxData.availability
                }, null, 8 /* PROPS */, ["availability"]))
              : _createCommentVNode("v-if", true),
            _cache[6] || (_cache[6] = _createTextVNode()),
            (_ctx.productBoxData.winemakerPrice)
              ? (_openBlock(), _createBlock(_component_winemaker_price, { key: 1 }))
              : _createCommentVNode("v-if", true),
            _cache[7] || (_cache[7] = _createTextVNode()),
            (_ctx.productBoxData.price || _ctx.productBoxData.soldoutRelation)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  (!_ctx.hidePrice)
                    ? (_openBlock(), _createBlock(_component_price_info, {
                        key: 0,
                        class: "product-box__price",
                        value: _ctx.productBoxData.price,
                        alcohol: _ctx.productBoxData.alcohol,
                        "show-star": true
                      }, null, 8 /* PROPS */, ["value", "alcohol"]))
                    : _createCommentVNode("v-if", true),
                  _cache[5] || (_cache[5] = _createTextVNode()),
                  _createVNode(_component_product_qty_and_cart_btn, {
                    "product-data": _ctx.productBoxData,
                    "product-link": _ctx.productLink,
                    state: _ctx.state,
                    "add-successor-active": _ctx.addSuccessorActive,
                    onQuantityChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('quantityChange', $event))),
                    onAddToBasket: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('addToBasket', $event))),
                    onOnRequestClicked: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('onRequestClicked', $event)))
                  }, null, 8 /* PROPS */, ["product-data", "product-link", "state", "add-successor-active"])
                ]))
              : _createCommentVNode("v-if", true)
          ])),
    _cache[13] || (_cache[13] = _createTextVNode()),
    (_ctx.productBoxData.showFoodSpecificationLink && !_ctx.hideCounterButton)
      ? (_openBlock(), _createElementBlock("a", {
          key: 8,
          href: _ctx.foodSpecificationLink,
          class: "product-box__link",
          innerHTML: _ctx.$t('detail.product.characteristics')
        }, null, 8 /* PROPS */, _hoisted_13))
      : _createCommentVNode("v-if", true)
  ]))
}