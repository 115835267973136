<template>
  <div class="product-box__segment-main">
    <ratings
      class="product-box__ratings"
      v-if="productBoxData.ratings > minimumRating && isBoxTypeVertical"
      :rating="productBoxData.ratings"
      :ratings-counter="productBoxData.ratingsCounter"
      :type="settings.rating.type"
      :lazy-load="true"
    />
    <div
      class="product-box__area"
      v-if="isBoxTypeVertical && productBoxData.area && settings.showArea"
    >
      {{ productBoxData.area }}
    </div>
    <lazy-load
      class="product-box__tags-wrapper"
      v-if="(productBoxData.flags || productBoxData.tags) && !productBoxData.inBasket"
      role="presentation"
    >
      <flags
        :class="{'product-box__product-flags--hidden': productBoxData.inBasket}"
        :flags="productBoxData.flags"
        :limit="isBoxTypeVertical ? 4 : 3"
        :award-limit="isBoxTypeVertical ? 2 : 1"
        :white-list="settings.flags.whitelistTag"
      />
      <tags
        class="product-box__tags"
        :class="{
          'product-box__tags--more': productBoxData.tags.length > 2,
          'product-box__tags--has-award': productBoxData.flags.awardImageUrls && productBoxData.flags.awardImageUrls.length
        }"
        v-if="isBoxTypeVertical"
        :tags="productBoxData.tags"
        @quantityChange="$emit('quantityChange', $event)"
      />
    </lazy-load>
    <a
      class="product-box__status product-box__status--basket"
      v-if="isBoxTypeVertical && productBoxData.inBasket"
      :href="basketUrl"
    >
      {{ $t('ads.ads_top_info.article_in_basket') }}
    </a>
    <a
      class="product-box__status product-box__status--basket product-box__status--successor-basket"
      v-if="isBoxTypeVertical && productBoxData.successorInBasket && !productBoxData.inBasket && addSuccessorActive"
      :href="basketUrl"
    >
      {{ $t('ads.ads_top_info.successor_in_basket') }}
    </a>
    <div
      v-if="productBoxData.zeroPriceItem || productBoxData.recurringOrderFollowUpPacket"
      class="product-box__price-wrapper product-box__price-wrapper--no-price"
    >
      <div class="product-box__buttons">
        <a
          class="product-box__status product-box__status--unavailable"
          :href="productBoxData.url"
        >
          {{ $t('availability.no-price') }}
        </a>
      </div>
    </div>
    <div
      v-else-if="productBoxData.availability.subscriptionPreview"
      class="product-box__price-wrapper product-box__price-wrapper--no-price"
    >
      <div class="product-box__buttons">
        <a
          class="product-box__status product-box__status--unavailable"
          :href="productBoxData.url"
        >
          {{ $t('availability.date.not.valid.yet') }}
        </a>
      </div>
    </div>
    <div
      class="product-box__conversion"
      v-else
    >
      <availability-info
        v-if="showAvailability"
        class="product-box__availability"
        :availability="productBoxData.availability"
      />
      <winemaker-price
        v-if="productBoxData.winemakerPrice"
      />
      <div
        class="product-box__price-wrapper"
        v-if="productBoxData.price || productBoxData.soldoutRelation"
      >
        <price-info
          v-if="!hidePrice"
          class="product-box__price"
          :value="productBoxData.price"
          :alcohol="productBoxData.alcohol"
          :show-star="true"
        />
        <product-qty-and-cart-btn
          :product-data="productBoxData"
          :product-link="productLink"
          :state="state"
          :add-successor-active="addSuccessorActive"
          @quantityChange="$emit('quantityChange', $event)"
          @addToBasket="$emit('addToBasket', $event)"
          @onRequestClicked="$emit('onRequestClicked', $event)"
        />
      </div>
    </div>
    <a
      v-if="productBoxData.showFoodSpecificationLink && !hideCounterButton"
      :href="foodSpecificationLink"
      class="product-box__link"
      v-html="$t('detail.product.characteristics')"
    />
  </div>
</template>

<script lang="ts">
  import {Options, Prop, Vue} from 'vue-property-decorator';
  import AvailabilityInfo from '../availabilityInfo/availabilityInfo.vue'
  import Ratings from '../ratings/ratings.vue'
  import Lightbox from '../lightbox/lightbox.vue'
  import Tags from '../tags/tags.vue'
  import Flags from '../flags/flags.vue';
  import type ProductBoxData from '../../types/ProductBoxData';
  import {ProductBoxSettings} from './productBoxSettings';
  import ProductQtyAndCartBtn from '../productQtyAndCartBtn/productQtyAndCartBtn.vue';
  import InfoPopup from '../infoPopup/infoPopup.vue';
  import WinemakerPrice from '../winemakerPrice/winemakerPrice.vue';
  import LazyLoad from '@/bps/components/lazyLoad/lazyLoad.vue';


  @Options({
    name: 'ProductBoxSegmentMain',
    components: {
      LazyLoad,
      WinemakerPrice,
      ProductQtyAndCartBtn,
      AvailabilityInfo,
      Ratings,
      Flags,
      Lightbox,
      Tags,
      InfoPopup
    },
  })
  export default class ProductBoxSegmentMain extends Vue {
    @Prop()
    productBoxData: ProductBoxData;
    @Prop()
    settings: ProductBoxSettings;
    @Prop()
    basketUrl: string;
    @Prop()
    foodSpecificationLink: string;
    @Prop()
    subscriptionLink: string;
    @Prop()
    hideCounterButton: boolean;
    @Prop()
    isBoxTypeVertical: boolean;
    @Prop()
    minimumRating: number;
    @Prop()
    showAvailability: boolean;
    @Prop({default: false})
    hidePrice: boolean;
    @Prop({default: false})
    addSuccessorActive: boolean;
    @Prop()
    state: string;
    @Prop()
    productLink: string;
  }
</script>
